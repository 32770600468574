import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import wahanaService from "../services/wahana.service";
import { useParams } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaCog,
  FaCalendarAlt,
  FaClock,
  FaListAlt,
} from "react-icons/fa";
import { detailMaintenanceWahanaPath, detailMisiPath } from "../routes";

const DetailWahana = () => {
  const { uuid } = useParams();
  const [wahanaDetails, setWahanaDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWahanaDetails = async () => {
      try {
        const data = await wahanaService.getWahanaById(uuid);
        if (data && data.wahana) {
          setWahanaDetails(data.wahana);
        } else {
          throw new Error("Data wahana tidak valid");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchWahanaDetails();
  }, [uuid]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-red-500 text-center">
        <p>Terjadi kesalahan saat mengambil data wahana.</p>
        <p>{error}</p>
      </div>
    );
  }
  if (!wahanaDetails) return <p>No wahana details available.</p>;

  const renderSpecifications = () => {
    const specificationItems = [];
  
    if (wahanaDetails.tipe === "Fixed Wing" || wahanaDetails.tipe === "VTOL") {
      specificationItems.push(
        <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200" key="wingspan">
          <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
            wingspan
          </span>
          <span className="mt-1 text-lg text-gray-900">
            {wahanaDetails.wingspan} mm
          </span>
        </div>
      );
    }
  
    if (wahanaDetails.tipe === "Multirotor" || wahanaDetails.tipe === "VTOL") {
      specificationItems.push(
        <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200" key="rotor">
          <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
            Rotor
          </span>
          <span className="mt-1 text-lg text-gray-900">
            {wahanaDetails.jumlah_rotor}
          </span>
        </div>
      );
    }
  
    return specificationItems.length > 0 ? specificationItems : null;
  };
  
  const truncateTextByChar = (text, charLimit) => {
    if (text && text.length > charLimit) {
      return text.slice(0, charLimit) + "...";
    }
    return text || "";
  };

  return (
    <div className="ml-cl0 mr-cr0 ">
      <h3 className="text-3xl text-new-300 pt-10">
        {wahanaDetails.nama_wahana}
      </h3>
      <p className="text-lg text-gray-700 mt-4 mb-8 leading-relaxed">
        {wahanaDetails.deskripsi_wahana}
      </p>
      <div className="detail_uav-container-subtitle">
        <Tabs defaultActiveKey="detail" id="tab" className="mb-3">
          <Tab eventKey="detail" title="Detail">
            <div className="mt-3 bg-white p-6 rounded-lg shadow-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
                  <span className="flex items-center font-bold text-blue-900 text-lg">
                    <FaListAlt className="mr-2" /> Nama
                  </span>
                  <span className="text-gray-900 text-xl">
                    {wahanaDetails.nama_wahana}
                  </span>
                </div>

                <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
                  <span className="flex items-center font-bold text-blue-900 text-lg">
                    {wahanaDetails.status ? (
                      <FaCheckCircle className="mr-2 text-green-600" />
                    ) : (
                      <FaTimesCircle className="mr-2 text-red-600" />
                    )}
                    Status
                  </span>
                  <span className="text-gray-900 text-xl">
                    {wahanaDetails.status ? "Aktif" : "Nonaktif"}
                  </span>
                </div>

                <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
                  <span className="flex items-center font-bold text-blue-900 text-lg">
                    <FaCog className="mr-2" /> Operasional
                  </span>
                  <span className="text-gray-900 text-xl">
                    {wahanaDetails.operasional} Menit
                  </span>
                </div>

                <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
                  <span className="flex items-center font-bold text-blue-900 text-lg">
                    <FaListAlt className="mr-2" /> Tipe
                  </span>
                  <span className="text-gray-900 text-xl">
                    {wahanaDetails.tipe}
                  </span>
                </div>

                <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
                  <span className="flex items-center font-bold text-blue-900 text-lg">
                    <FaCalendarAlt className="mr-2" />
                    Ditambahkan
                  </span>
                  <span className="text-gray-900 text-xl">
                    {wahanaDetails.createdAt}
                  </span>
                </div>

                <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
                  <span className="flex items-center font-bold text-blue-900 text-lg">
                    <FaClock className="mr-2" />
                    Diperbarui
                  </span>
                  <span className="text-gray-900 text-xl">
                    {wahanaDetails.updatedAt}
                  </span>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="spesifikasi" title="Spesifikasi">
            <div className="mt-6 p-6 bg-gray-100 rounded-lg shadow-md">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {renderSpecifications()}
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Length
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.length} mm
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Material
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.material}
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Propulsi
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.propulsi}
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Baterai
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.baterai}
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Payload
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.payload} cm³
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Durasi
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.durasi} Menit
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Cakupan
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.cakupan} ha
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Ketinggian
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.ketinggian} m
                  </span>
                </div>
                <div className="flex flex-col p-4 bg-white rounded-lg border border-gray-200">
                  <span className="font-semibold text-gray-700 text-sm uppercase tracking-wide">
                    Kapasitas
                  </span>
                  <span className="mt-1 text-lg text-gray-900">
                    {wahanaDetails.kapasitas} gr
                  </span>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="misi" title="Misi">
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {wahanaDetails.missions.map((mission) => (
                <a
                  key={mission.id}
                  href={`${detailMisiPath}/${mission.uuid}`}
                  className="no-underline hover:no-underline text-inherit"
                >
                  <div
                    key={mission.id}
                    className="bg-white border-l-4 border-blue-700 text-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                  >
                    <h4 className="text-xl font-bold mb-2">
                      {truncateTextByChar(mission.judul_misi, 20)}
                    </h4>
                    <p className="text-sm mb-1">Kategori: {mission.kategori}</p>
                    <p className="text-sm">Tanggal: {mission.createdAt}</p>
                  </div>
                </a>
              ))}
            </div>
          </Tab>
          <Tab eventKey="perbaikan" title="Perbaikan">
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {wahanaDetails.wahana_perbaikanWahana.map((perbaikan) => (
                <a
                  key={perbaikan.id}
                  href={`${detailMaintenanceWahanaPath}/${perbaikan.uuid}`}
                  className="no-underline hover:no-underline text-inherit"
                >
                  <div
                    key={perbaikan.id}
                    className="bg-white border-l-4 border-blue-700 text-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                  >
                    <h4 className="text-xl font-bold mb-2">
                      {perbaikan.judul_perbaikan}
                    </h4>
                    <p className="text-sm mb-1">
                      Kategori: {perbaikan.kategori}
                    </p>
                    <p className="text-sm">Tanggal: {perbaikan.createdAt}</p>
                    <p className="text-sm">
                      Biaya:{" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(perbaikan.biaya)}
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Tab>
          <Tab eventKey="foto" title="Foto">
            <div className="mt-3">
              <div className="mb-6">
                <h4 className="text-lg font-semibold text-gray-800">Foto</h4>
                <div className="grid grid-cols-3 gap-4">
                  {wahanaDetails.foto_wahanas &&
                  wahanaDetails.foto_wahanas.length > 0 ? (
                    wahanaDetails.foto_wahanas.map((foto, index) => (
                      <Zoom key={index}>
                        <img
                          src={foto.foto_urls}
                          alt={`Foto ${index + 1}`}
                          className="w-full h-60 object-cover border border-gray-300 shadow-sm rounded-lg cursor-pointer mb-3"
                        />
                      </Zoom>
                    ))
                  ) : (
                    <p className="text-gray-600">Tidak ada foto tersedia.</p>
                  )}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default DetailWahana;
